/* App.css */

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif; /* iPhone-like font */
  background-color: #121212; /* Тёмный фон */
  color: #ffffff; /* Светлый текст */
}

.app {
  text-align: center;
}


header {
  padding: 20px;
  position: relative;
}

.image-gallery {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Всегда два столбца */
  gap: 16px; /* Расстояние между элементами */
  justify-content: center;
  padding: 20px;
}



.image-card {
  background-color: #1e1e1e;
  border-radius: 8px;
  overflow: hidden;
  cursor: pointer;
  transition: transform 0.2s;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.image-card:hover {
  transform: scale(1.05);
}

.image {
  width: 100%;
  height: 150px; /* Фиксированная высота для изображений */
  object-fit: cover; /* Изображение заполняет карточку без искажений */
  border-bottom: 1px solid #333;
}

.image-info {
  padding: 10px;
}

.image-info h3 {
  font-size: 16px;
  margin: 5px 0;
  color: #ffffff;
}

.image-info p {
  font-size: 14px;
  color: #aaaaaa;
}

.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
}

.spinner {
  border: 6px solid rgba(255, 255, 255, 0.3);
  border-top: 6px solid #ffffff;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.profile-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.profile-icon-image {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 2px solid #fff;
}

.profile {
  text-align: center;
  margin-top: 50px;
}

.avatar {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-bottom: 20px;
}

.back-button {
  margin-bottom: 20px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
}

.profile-info h2 {
  margin-top: 10px;
}

.bottom-overlay {
  position: absolute;
  bottom: 10px;
  left: 10px;
  right: 10px;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-radius: 15px;
  backdrop-filter: blur(8px); /* Blur effect */
  -webkit-backdrop-filter: blur(8px); /* Fallback for Safari */
}

.bottom-overlay span {
  margin-bottom: 5px;
  font-family: inherit; /* Ensure it inherits the global font */
}

.bottom-overlay .title {
  font-weight: bold; /* Bold font for titles */
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif; /* iPhone-like font */
}

/* Add a fallback for devices that do not support backdrop-filter */
@supports not ((backdrop-filter: blur(8px)) or (-webkit-backdrop-filter: blur(8px))) {
  .bottom-overlay {
    background: rgba(0, 0, 0, 0.8); /* Slightly darker background as fallback */
  }
}
